<template>
  <div></div>
</template>

<script>
import LocalStorageService from "@/plugins/LocalStorageService";
import { mapActions } from "vuex";
const localStorageService = LocalStorageService.getService();
export default {
  name: "GoogleSighInSuccess",
  created() {
    console.log(this.$route.query.code);
    this.authGoogleAccount(this.$route.query.code);
  },
  methods: {
    ...mapActions(["authGoogle"]),
    // 구글 토큰 인증
    authGoogleAccount(code) {
      const response = this.$Api.authGoogle(code);
      response
        .then((res) => {
          if (res.data.code === 0) {
            opener.location.reload();
            window.close();
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
  },
};
</script>
